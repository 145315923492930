import './Polyfills';
import './tracing';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './overrides.d';

// Needs to be before Root
if (import.meta.env.DEV) {
  const { default: whyDidYouRender } = await import('@welldone-software/why-did-you-render');
  whyDidYouRender(React as any, {
    trackAllPureComponents: false
  });
}

import Root from './components/Root';
// import log from './log';
import './index.css';

const root = createRoot(document.getElementById('app')!);
root.render(<Root />);
